<template>
  <div>
    <el-form ref="tableQuery" :inline="true" :model="tableQuery" size="small">
      <el-form-item label="维护人" prop="user_id">
        <el-select v-model="tableQuery.user_id" clearable remote class="el-input_inner--rewrite">
          <el-option v-for="user in option" :key="user.id" :label="user.name" :value="user.id">
            <span>{{ user.name }}</span>
          </el-option>
          <el-pagination background layout="prev, pager, next, total" :total="optionTotal"
            :current-page.sync="optionQuery.page" :page-size="optionQuery.page_size" @current-change="getDriverData">
          </el-pagination>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      border stripe size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="维护人" prop="user_info.name"></el-table-column>
      <el-table-column label="车辆" prop="car_info.name"></el-table-column>
      <el-table-column label="车牌号" prop="car_info.car_number"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作" v-slot="{ row }">
        <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="tableTotal"
        :current-page.sync="tableQuery.page" :page-size="tableQuery.page_size" @current-change="getTableData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="维护人" prop="name">
          <el-select v-model="form.user_id" clearable remote class="el-input_inner--rewrite">
            <el-option v-for="user in optionDialog" :key="user.id" :label="user.name" :value="user.id">
              <span>{{ user.name }}</span>
            </el-option>
            <!-- <el-pagination background layout="prev, pager, next, total" :total="optionTotalDialog"
              :current-page.sync="optionQueryDialog.page" :page-size="optionQueryDialog.page_size"
              @current-change="getDriverDataDialog">
            </el-pagination> -->
          </el-select>
        </el-form-item>
        <el-form-item label="车辆" prop="name">
          <el-select v-model="form.car_info_id" clearable remote class="el-input_inner--rewrite">
            <el-option v-for="user in carOptionDialog" :key="user.id" :label="user.name" :value="user.id">
              <div class="flex justify-content-s-b">
                <span>{{ user.name }}</span><span style="color: #8492a6">{{ user.car_number }}</span>
              </div>
            </el-option>
            <!-- <el-pagination background layout="prev, pager, next, total" :total="carOptionTotalDialog"
              :current-page.sync="carOptionQueryDialog.page" :page-size="carOptionQueryDialog.page_size"
              @current-change="getCarDialog">
            </el-pagination> -->
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
  import {
    getDriverDataAPI,
    getCarRecordListAPI,
    getVehicleDataAPI,
    saveCarRecordListAPI,
  } from '@/api/dispatch-vehicle.js'
  export default {
    data() {
      return {
        // 司机选项请求参数
        optionQuery: {
          page: 1,
          page_size: 10,
        },
        // 司机选项总数
        optionTotal: 1,
        // 司机选项
        option: [],
        // 表格数据
        tableData: [],
        // 表格请求参数
        tableQuery: {
          page: 1,
          page_size: 10,
          user_id: '', // 司机id
        },
        // 表格数据总数
        tableTotal: 1,
        // 表格加载状态
        tableLoading: false,

        // 弹窗显示状态
        showDialog: false,

        // 弹窗内容
        form: {
          remark: '', // 备注
          user_id: '', // 司机id
          car_info_id: '', // 车辆id
        },
        // 弹窗司机选项请求参数
        optionQueryDialog: {
          page: 1,
          page_size: 1000,
        },
        // 弹窗司机选项总数
        optionTotalDialog: 1,
        // 弹窗司机选项
        optionDialog: [],
        // 弹窗提交状态
        submitting: false,
        // 弹窗标题
        dialogTitle: '',

        // 车辆请求参数
        carOptionQueryDialog: {
          page: 1,
          page_size: 1000,
        },
        // 车辆数据
        carOptionDialog: [],
        // 车辆总数
        carOptionTotalDialog: 1,

        // 是否为编辑状态
        isEdit: false,
        // 编辑id
        EditId: 0,
      }
    },
    created() {
      this.getDriverData();
      this.getTableData();
    },
    methods: {
      // 查询
      handleSearch() {
        this.tableData = [];
        this.tableQuery.page = 1;
        this.getTableData();
      },
      // 新增
      handleAdd() {
        this.showDialog = true;
        this.getDriverDataDialog();
        this.getCarDialog();
        this.dialogTitle = '新增';
      },
      // 获取司机数据
      getDriverData() {
        getDriverDataAPI(this.optionQuery).then(res => {
          this.option = res.data
          this.optionTotal = res.total
        })
      },
      // 获取表格数据
      getTableData() {
        this.tableLoading = true;
        getCarRecordListAPI(this.tableQuery).then(res => {
          this.tableLoading = false;
          this.tableData = res.data
          this.tableTotal = res.total
        })
      },
      // 编辑
      handleEdit(row) {
        this.isEdit = true;
        this.EditId = row.id;
        this.form.user_id = typeof row.user_id == 'string' ? Number(row.user_id) : row.user_id;
        this.form.remark = row.remark;
        this.form.car_info_id = typeof row.car_info_id == 'string' ? Number(row.car_info_id) : row.car_info_id;
        this.dialogTitle = '编辑';
        this.getDriverDataDialog();
        this.getCarDialog();

        this.showDialog = true;
      },
      // 关闭弹窗
      closeDialog() {
        this.showDialog = false;
        this.form.remark = '';
        this.form.user_id = '';
        this.form.car_info_id = '';
        delete this.form.id
        this.isEdit = false;
        this.EditId = 0;
      },
      // 获取弹窗中司机列表
      getDriverDataDialog() {
        getDriverDataAPI(this.optionQueryDialog).then(res => {
          this.optionDialog = res.data
          this.optionTotalDialog = res.total
        })
      },
      // 提交弹窗
      handleSubmit() {
        this.submitting = true;
        if (this.isEdit) {
          this.form.id = this.EditId;
        }
        saveCarRecordListAPI(this.form).then(() => {
          this.submitting = false;
          this.tableData = [];
          this.tableQuery.page = 1;
          this.getTableData();
          this.closeDialog();
        })
      },
      // 获取弹窗中车辆数据
      getCarDialog() {
        getVehicleDataAPI(this.carOptionQueryDialog).then(res => {
          this.carOptionDialog = res.data
          this.carOptionTotalDialog = res.total
        })
      }
    }
  }
</script>


<style scoped>

</style>